<template lang="pug">
  v-card
    v-card-content
      .kt-form(v-if="loaded")
        h2.title  {{ 'pages.setup_wizard.data_and_synchronization' | translate}}
        p.subtitle {{ 'pages.setup_wizard.type_of_syncronize_message' | translate}}
        .kt-heading.kt-heading--md
        .kt-form__section.kt-form__section--first
          .kt-wizard-v1__form
            .form-group.row
              label.col-md-3.col-form-label.title {{ 'pages.setup_wizard.data_source' | translate}}
              .col-md-3
                .kt-radio-inline
                  label.kt-radio.subtitle {{ 'pages.setup_wizard.cloud' | translate}}
                    input(
                      disabled
                      type="radio"
                      name="sync-provider"
                      :checked="checkStep(syncTypes.CLOUD_PROVIDER)"
                      @click="changeDataSource(syncTypes.CLOUD_PROVIDER)" )
                    span
              //- .col-md-3
                .kt-radio-inline
                  label.kt-radio.subtitle {{ 'pages.setup_wizard.csv_import' | translate}}
                    input(
                      disabled
                      type="radio"
                      name="sync-provider"
                      :checked="checkStep(syncTypes.CSV_IMPORT)"
                      @click="changeDataSource(syncTypes.CSV_IMPORT)")
                    span
              .col-md-3
                .kt-radio-inline
                  label.kt-radio.subtitle {{ 'pages.setup_wizard.manual' | translate}}
                    input(
                      disabled
                      type="radio"
                      name="sync-provider"
                      :checked="checkStep(syncTypes.MANUAL)"
                      @click="changeDataSource(syncTypes.MANUAL)")
                    span

            sync-provider-form(
              :startSync="startSync"
              :secretInfo="secretInfo"
              v-show="checkStep(syncTypes.CLOUD_PROVIDER)"
              @on-load-data="storeSync")
            manual-form(
              isSetupPage
              v-show="checkStep(syncTypes.MANUAL)")
            manual-form(
              isSetupPage
              isImportType
              v-show="checkStep(syncTypes.CSV_IMPORT)"
              @on-file-uploaded="nextStep")
      .preloader-wrap(v-else)
        v-preloader
    ui-modal(
      :show="isLoadingSync"
      :isLoadingModal="true")

</template>

<script>
import api from '@/api'
import UiModal from '@/components/ui/Modal'
import ManualForm from '@/views/setup/dataSync/ManualForm'
import SyncProviderForm from '@/views/setup/dataSync/SyncProviderForm'
import { mapActions } from 'vuex'
import { DATA_SYNC_TYPES } from '@/util/constants'

export default {
  name: 'DataSyncSettings',

  components: {
    SyncProviderForm,
    ManualForm,
    UiModal
  },

  async mounted () {
    const { data } = await api.restoreDataSyncProvider()
    // data.type = DATA_SYNC_TYPES.CLOUD_PROVIDER // TODO: remove it
    if (data.type === DATA_SYNC_TYPES.CLOUD_PROVIDER || !data.type) {
      this.currentStep = DATA_SYNC_TYPES.CLOUD_PROVIDER
    } else {
      this.currentStep = DATA_SYNC_TYPES.MANUAL
    }
    this.currentTypeSync = data.type
    this.secretInfo.syncProvider = data.provider || this.secretInfo.syncProvider
    this.secretInfo.providerConnectData.tenant = data.providerConnectData ? data.providerConnectData.tenant : ''
    this.loaded = true
  },

  data: () => ({
    loaded: false,
    currentTypeSync: DATA_SYNC_TYPES.CLOUD_PROVIDER,
    secretInfo: {
      syncProvider: {},
      providerConnectData: { tenant: '' }
    },
    currentStep: DATA_SYNC_TYPES.CLOUD_PROVIDER,
    isLoadingSync: false,
    enableNextStepForSync: false
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors',
      'setPopupMessage'
    ]),

    changeDataSource (param) {
      this.currentStep = param
    },

    async nextStep () {
      this.$router.push('/administration/employees/import')
    },

    async startSync () {
      this.clearErrors()
      this.isLoadingSync = true

      const { error } = await api.startDataSyncProvider()
      this.isLoadingSync = false

      if (!error) {
        this.setPopupMessage({
          message: this.$t('infotext.data_was_sync')
        })
        return error
      }
      return error
    },

    async storeSync () {
      this.clearErrors()
      this.isLoadingSync = true
      const storeType = await api.storeDataSyncType({ value: 'CLOUD_PROVIDER' })
      if (storeType.error) {
        this.isLoadingSync = false
        return
      }

      const body = {}
      body.providerId = this.secretInfo.syncProvider.id
      body.providerConnectData = { tenant: this.secretInfo.providerConnectData.tenant }
      await api.storeDataSyncProvider(body)
      this.isLoadingSync = false
    },

    checkStep (step) {
      return this.currentStep === step
    }
  },

  computed: {
    syncTypes () {
      return DATA_SYNC_TYPES
    }
  }
}
</script>

<style lang="scss" scoped>
  .preloader-wrap {
    position: relative;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
